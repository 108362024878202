exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-team-activity-tsx": () => import("./../../../src/pages/admin-team/activity.tsx" /* webpackChunkName: "component---src-pages-admin-team-activity-tsx" */),
  "component---src-pages-admin-team-country-tsx": () => import("./../../../src/pages/admin-team/country.tsx" /* webpackChunkName: "component---src-pages-admin-team-country-tsx" */),
  "component---src-pages-admin-team-index-tsx": () => import("./../../../src/pages/admin-team/index.tsx" /* webpackChunkName: "component---src-pages-admin-team-index-tsx" */),
  "component---src-pages-admin-team-modify-activity-tsx": () => import("./../../../src/pages/admin-team/modify-activity.tsx" /* webpackChunkName: "component---src-pages-admin-team-modify-activity-tsx" */),
  "component---src-pages-admin-team-modify-country-tsx": () => import("./../../../src/pages/admin-team/modify-country.tsx" /* webpackChunkName: "component---src-pages-admin-team-modify-country-tsx" */),
  "component---src-pages-admin-team-modify-tags-tsx": () => import("./../../../src/pages/admin-team/modify-tags.tsx" /* webpackChunkName: "component---src-pages-admin-team-modify-tags-tsx" */),
  "component---src-pages-admin-team-operators-tsx": () => import("./../../../src/pages/admin-team/operators.tsx" /* webpackChunkName: "component---src-pages-admin-team-operators-tsx" */),
  "component---src-pages-admin-team-tags-tsx": () => import("./../../../src/pages/admin-team/tags.tsx" /* webpackChunkName: "component---src-pages-admin-team-tags-tsx" */),
  "component---src-pages-discover-tour-operators-tsx": () => import("./../../../src/pages/discover-tour-operators.tsx" /* webpackChunkName: "component---src-pages-discover-tour-operators-tsx" */),
  "component---src-pages-operator-external-messages-tsx": () => import("./../../../src/pages/operator/external/messages.tsx" /* webpackChunkName: "component---src-pages-operator-external-messages-tsx" */),
  "component---src-pages-operator-index-tsx": () => import("./../../../src/pages/operator/index.tsx" /* webpackChunkName: "component---src-pages-operator-index-tsx" */),
  "component---src-pages-operator-onboarding-tsx": () => import("./../../../src/pages/operator/onboarding.tsx" /* webpackChunkName: "component---src-pages-operator-onboarding-tsx" */),
  "component---src-pages-operator-profile-org-id-tsx": () => import("./../../../src/pages/operator/profile/[OrgId].tsx" /* webpackChunkName: "component---src-pages-operator-profile-org-id-tsx" */),
  "component---src-pages-operator-scheduled-calls-tsx": () => import("./../../../src/pages/operator/scheduled-calls.tsx" /* webpackChunkName: "component---src-pages-operator-scheduled-calls-tsx" */),
  "component---src-pages-operator-tour-packages-tsx": () => import("./../../../src/pages/operator/tour-packages.tsx" /* webpackChunkName: "component---src-pages-operator-tour-packages-tsx" */),
  "component---src-pages-operator-user-[uid]-tsx": () => import("./../../../src/pages/operator/user/[uid].tsx" /* webpackChunkName: "component---src-pages-operator-user-[uid]-tsx" */),
  "component---src-pages-payment-received-tsx": () => import("./../../../src/pages/payment-received.tsx" /* webpackChunkName: "component---src-pages-payment-received-tsx" */),
  "component---src-pages-redirect-login-tsx": () => import("./../../../src/pages/redirect/login.tsx" /* webpackChunkName: "component---src-pages-redirect-login-tsx" */),
  "component---src-pages-redirect-tour-operator-sign-up-tsx": () => import("./../../../src/pages/redirect/tour-operator-sign-up.tsx" /* webpackChunkName: "component---src-pages-redirect-tour-operator-sign-up-tsx" */),
  "component---src-pages-redirect-user-sign-up-tsx": () => import("./../../../src/pages/redirect/user-sign-up.tsx" /* webpackChunkName: "component---src-pages-redirect-user-sign-up-tsx" */),
  "component---src-pages-register-as-a-tour-operator-tsx": () => import("./../../../src/pages/register-as-a-tour-operator.tsx" /* webpackChunkName: "component---src-pages-register-as-a-tour-operator-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-tour-operator-[id]-[name]-tsx": () => import("./../../../src/pages/tour-operator/[id]/[name].tsx" /* webpackChunkName: "component---src-pages-tour-operator-[id]-[name]-tsx" */),
  "component---src-pages-user-confirm-payment-[id]-tsx": () => import("./../../../src/pages/user/confirm-payment/[id].tsx" /* webpackChunkName: "component---src-pages-user-confirm-payment-[id]-tsx" */),
  "component---src-pages-user-index-tsx": () => import("./../../../src/pages/user/index.tsx" /* webpackChunkName: "component---src-pages-user-index-tsx" */),
  "component---src-pages-user-messages-tsx": () => import("./../../../src/pages/user/messages.tsx" /* webpackChunkName: "component---src-pages-user-messages-tsx" */),
  "component---src-pages-user-profile-[uid]-tsx": () => import("./../../../src/pages/user/profile/[uid].tsx" /* webpackChunkName: "component---src-pages-user-profile-[uid]-tsx" */),
  "component---src-templates-country-tsx": () => import("./../../../src/templates/country.tsx" /* webpackChunkName: "component---src-templates-country-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-tour-operator-tsx": () => import("./../../../src/templates/tour-operator.tsx" /* webpackChunkName: "component---src-templates-tour-operator-tsx" */)
}

