import React, { ReactNode, useEffect, useContext } from "react";
import { AdminNav } from "../../components/AdminNav";
import { FirebaseAuthContext } from "../../context/firebase-auth";
import { getCustomClaims } from "../../util/util";
import { navigate } from "gatsby";

const RedirectAdmin = () => {
    const user = useContext(FirebaseAuthContext);

    useEffect(() => {
        if (user !== null) {
            verifyAdmin();
        }
    }, [user]); 

    const verifyAdmin = async () => {
        if (user === null) return;
        const token = await getCustomClaims(user);
        if(!token.claims["CLOUDSAFARI_ADMIN"]) {
            navigate('/');
        }
    }
    
    return null;
};

const wrapPagesDeep = ({ element } : { element: ReactNode }) => {

    return (
        <div className="tw-flex tw-flex-col tw-p-4">
            <AdminNav />
            <RedirectAdmin />
            <div>
                {element}
            </div>
        </div>
    )
}

export default wrapPagesDeep;
export {
    wrapPagesDeep,
}