import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { Menu, X } from "react-feather";
import { 
    AiOutlineMessage,
    AiOutlineCalendar,
    AiOutlineFileText,
    AiOutlineQuestion,
    AiOutlineHome,
} from "react-icons/ai";
import { CgProfile, CgOrganisation } from "react-icons/cg";
import ClientOperatorMessage, { getDefaultClientOperatorMessage } from "shared-models/build/client/operators";
import { FirebaseAuthContext, Logout } from "../context/firebase-auth";
import CSLogo from "../images/cs-logo-with-text.svg";
import { sendPostRequest } from "../util/util";

interface SideLinkProps {
    icon: ReactNode;
    text: string;
    selected: string;
    link?: string;
    href?: string;
    newPage?: boolean;
    setSelectedMenu: Function;
    setOpen: Function;
    open: boolean;
}

const SideLink: FC<SideLinkProps> = ({ icon, text, selected, link, href, newPage, setSelectedMenu, setOpen, open }) => {

    const innerElements = (
        <div className={`tw-flex tw-w-full tw-my-2 tw-py-2 tw-items-center tw-justify-between tw-pl-3
        ${ selected === link || selected === href ? "tw-border-r-safari-green tw-border-r-4" : "" }
        `}>
            <span className="tw-pr-4 tw-flex tw-items-center tw-text-sm"><span className="tw-pr-3 tw-text-lg">{icon}</span> {text}</span> 
        </div>
    );

    if (link) {
        return (
            <Link to={link} className="tw-w-full" onClick={() => {
                setSelectedMenu(link);
                setOpen(!open);
            }}>
                {innerElements}
            </Link>
        )
    } else if (href) {
        return (
            <a href={href} target={ newPage ? "_blank" : ""} className="tw-w-full">
                {innerElements}
            </a>
        )
    }

    return innerElements;
}

enum OperatorLinks {
    Message = "/operator/external/messages",
    TourPackages = "/operator/tour-packages",
    Profile = "/operator/profile",
    GetHelp = "mailto:hello@mycloudsafari.com",
    Onboarding = "/operator/onboarding",
    Home = "/operator",
    User = "/user",
}



const OperatorNav = () => {
    const [selectedMenu, setSelectedMenu] = useState("");
    const [open, setOpen] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState<number>(0);
    const user = useContext(FirebaseAuthContext);
    const [operator, setOperator] = useState<ClientOperatorMessage>(getDefaultClientOperatorMessage());

    useEffect(() => {
        setSelectedMenu(window.location.pathname);
    }, []);

    useEffect(() => {
        getOperator();
    }, [user]);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
        })
        setWindowWidth(window.innerWidth);
    }, [])

    const getOperator = async () => {
        if (user === null) return;
        const [op, opErr] = await sendPostRequest(user, "/organization/get-operator-with-uid", {}, "", "Failed to get the operator data.");
        if (opErr) {
            console.error(op, opErr);
            return;
        }
        setOperator(op);
    }

    if (!open && windowWidth < 768) return (
        <div className="tw-fixed tw-top-[20px] tw-right-[20px] tw-z-50 tw-bg-white tw-p-1 tw-shadow-lg tw-rounded-full">
            <Menu size={30} onClick={() => setOpen(!open)} className="tw-cursor-pointer" />
        </div>
    )

    return (
        <div className="tw-w-full tw-fixed tw-top-0 tw-left-0 md:tw-sticky tw-bg-white tw-border-r-[1px] tw-h-screen">
            <div className="tw-p-2 tw-flex tw-flex-col tw-justify-between tw-h-full">
                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                    <div className="tw-w-full tw-flex tw-justify-end md:tw-hidden tw-p-3">
                        <X onClick={() => setOpen(!open)} size={25} className="tw-cursor-pointer" />
                    </div>
                    <div className="tw-w-[150px] tw-mb-8">
                        <Link to="/" className="tw-flex tw-justify-center" onClick={() => setOpen(false)}>
                            <img 
                                src={CSLogo}
                                className="tw-max-w-[120px]"
                            />
                        </Link>                
                    </div>

                    <SideLink 
                        icon={<AiOutlineHome />}
                        text="Home"
                        selected={selectedMenu}
                        link={OperatorLinks.Home}
                        setSelectedMenu={setSelectedMenu}
                        setOpen={setOpen}
                        open={open}
                    />

                    <SideLink 
                        icon={<AiOutlineMessage />}
                        text="Messages"
                        selected={selectedMenu}
                        link={OperatorLinks.Message}
                        setSelectedMenu={setSelectedMenu}
                        setOpen={setOpen}
                        open={open}
                    />

                    <SideLink 
                        icon={<CgOrganisation />}
                        text="Organization"
                        selected={selectedMenu}
                        link={`/operator/profile/${operator.OrgId}`}
                        setSelectedMenu={setSelectedMenu}
                        setOpen={setOpen}
                        open={open}
                    />

                    <SideLink 
                        icon={<CgProfile />}
                        text="User Profile"
                        selected={selectedMenu}
                        link={`/operator/user/${user?.uid}`}
                        setSelectedMenu={setSelectedMenu}
                        setOpen={setOpen}
                        open={open}
                    />

                    <SideLink 
                        icon={<AiOutlineQuestion />}
                        text="Get Help"
                        selected={selectedMenu}
                        href={OperatorLinks.GetHelp}
                        setSelectedMenu={setSelectedMenu}
                        setOpen={setOpen}
                        open={open}
                    />

                </div>
                <div className="tw-flex tw-justify-center tw-mb-4">
                    <button
                        className="tw-red-button tw-w-full"
                        onClick={() => {
                            setOpen(false);
                            Logout("/");
                        }}
                    >
                        Log out
                    </button>
                </div>
            </div>
        </div>
    )
}

const OperatorMobileNav = () => {
    return (
        <div className="tw-w-full">
            <div className="tw-flex tw-justify-center">
                <div className="tw-w-[150px]">
                <Link to="/">
                    <StaticImage src="../images/cs-logo-with-text.svg" imgClassName="tw-max-w-[100px]" objectFit="contain" alt="CloudSafari" loading="eager" placeholder="none" />
                </Link>          
                </div>      
            </div>
            <div>
                
            </div>
        </div>
    )
}

export {
    OperatorNav,
    OperatorMobileNav
}