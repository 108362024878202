interface Tag {
    Name: string;
    Description: string;
    IconURL: string;
}

enum TagIDS {
    GORILLA_TREKKING = "Gorilla Trekking",
    SAFARI = "Safari",
    MOUNTAIN_CLIMBING = "Mountain Climbing",
    ADVENTURE_ACTIVITIES = "Adventure Activities",
    BEACHES = "Beaches",
    SCUBA_DIVING = "Scuba Diving",
    NIGHTLIFE = "Nightlife",
    OPEN_CAR_SAFARI = "Open Car Safari",
    BIKE_TOURS = "Bike Tours",
    VOLUNTEER = "Volunteer",
    CULTURAL = "Cultural",
    DESERT = "Desert",
    SITE_SEEING = "Site Seeing",
    WALKING_SAFARI = "Walking Safari",
    BOAT_TOURS = "Boat Tours",
}

const Tags : { [key: string]: Tag } = {
    [TagIDS.GORILLA_TREKKING]: {
        Name: "Gorilla Trekking",
        Description: "",
        IconURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/tags%2Fgorilla.svg?alt=media&token=6928e59d-9f3e-4fbd-94f3-10534f28a9f3",
    },
    [TagIDS.SAFARI]: {
        Name: "Safari",
        Description: "",
        IconURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/tags%2Fopenmoji_giraffe.svg?alt=media&token=f5a84b79-684f-452a-9a88-01ccf3353b6c",
    },
    [TagIDS.MOUNTAIN_CLIMBING]: {
        Name: "Mountain Climbing",
        Description: "",
        IconURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/tags%2Flucide_mountain-snow.svg?alt=media&token=fe500e4a-44f8-4117-936c-032e2620b8c7",
    },
    [TagIDS.BEACHES]: {
        Name: "Beaches",
        Description: "",
        IconURL: "",
    },
    [TagIDS.SCUBA_DIVING]: {
        Name: "Scuba Diving",
        Description: "",
        IconURL: "",
    },
    [TagIDS.NIGHTLIFE]: {
        Name: "Nightlife",
        Description: "",
        IconURL: "",
    },
    [TagIDS.OPEN_CAR_SAFARI]: {
        Name: "Open Car Safari",
        Description: "",
        IconURL: "",
    },
    [TagIDS.BIKE_TOURS]: {
        Name: "Bike Tours",
        Description: "",
        IconURL: "",
    },
    [TagIDS.VOLUNTEER]: {
        Name: "Volunteer",
        Description: "",
        IconURL: "",
    },
    [TagIDS.ADVENTURE_ACTIVITIES]: {
        Name: "Adventure Activities",
        Description: "",
        IconURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/tags%2Fmap_climbing.svg?alt=media&token=923d4de2-7a58-4d84-8beb-f05a7c34e008",
    },
    [TagIDS.CULTURAL]: {
        Name: "Cultural",
        Description: "",
        IconURL: "",
    },
    [TagIDS.DESERT]: {
        Name: "Desert",
        Description: "",
        IconURL: "",
    },
    [TagIDS.SITE_SEEING]: {
        Name: "Site Seeing",
        Description: "",
        IconURL: "",
    },
    [TagIDS.WALKING_SAFARI]: {
        Name: "Walking Safari",
        Description: "",
        IconURL: "",
    },
    [TagIDS.BOAT_TOURS]: {
        Name: "Boat Tours",
        Description: "",
        IconURL: "",
    }
};

export {
    Tags,
    TagIDS,
}