import React, { FC } from "react";
import ReactModal from "react-modal";
import { X } from "react-feather";

interface Props {
    children: React.ReactNode,
    open: boolean,
    contentLabel: string,
    setOpen: Function,
}

const Modal: FC<Props> = ({ children, open, contentLabel, setOpen }) => {
    return (
        <ReactModal
            isOpen={open}
            contentLabel={contentLabel}
            className="tw-w-[90vw] md:tw-w-[45vw] tw-max-h-[90vh] tw-overflow-y-auto tw-bg-white tw-rounded-lg tw-p-[30px]"
            overlayClassName="tw-fixed tw-left-0 tw-right-0 tw-top-0 tw-bottom-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center"
        >
            <div className="tw-flex tw-justify-end">
                <button onClick={() => setOpen(false)}>
                    <X />
                </button>
            </div>
            {children}
            <div className="tw-pt-[30px]"></div>
        </ReactModal>
    )
}

export {
    Modal,
}