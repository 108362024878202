import * as React from "react";
import { User, Menu as MenuIcon, ShoppingCart, Search, X } from 'react-feather';
import { TempFormDataContext } from "../context/temp-form-data";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { ALL_COUNTRIES } from "../../static/data/countries";
import { ALL_MONTHS } from "../../static/data/months";
import { Register } from "./Register";
import { navigate } from "gatsby";

const COUNTRY_KEYS = Object.keys(ALL_COUNTRIES);
const MONTH_VALUES = Object.values(ALL_MONTHS);

const Navbar = () => {
    const { formData, setFormData } = React.useContext(TempFormDataContext);
    const [open, setOpen] = React.useState(false);
    const [cartSelection, setCartSelection] = React.useState<number>(0);
    const [openPopup, setOpenPopup] = React.useState<boolean>(false);

    React.useEffect(() => {
        const selection = Object.keys(formData.Selection).reduce((num: number, key: string) => {
            const items = Object.values(formData.Selection[key]);
            return num + items.length;
        }, 0);
        setCartSelection(selection);
    }, [formData]);



    if (!openPopup) return (

        <div>
            {/* STICKY NAVBARS*/}
            <div className="tw-sticky tw-top-0 tw-bg-white">
                <div className="tw-flex tw-flex-row tw-items-center tw-justify-evenly">

                    {/* Logo w/Text - LG & Up */}
                    <div className="tw-w-[33%] tw-hidden lg:tw-block">
                        <Link to="/">
                            <StaticImage src="../images/cs-logo-with-text.svg" imgClassName="tw-max-w-[180px]" objectFit="contain" alt="CloudSafari" loading="eager" placeholder="none" />
                        </Link>
                    </div>
                    {/* Logo NO text  - MD Only*/}
                    <div className="tw-w-[33%] tw-hidden md:tw-block lg:tw-hidden tw-pt-8">
                        <Link to="/">
                            <StaticImage src="../images/cs-logo-no-text.svg" className="tw-w-[40px]" objectFit="contain" alt="CloudSafari" loading="eager" placeholder="none" />
                        </Link>
                    </div>

                    {/* Search Bar HIDDEN ATM*/}
                    <div className="tw-hidden tw-pt-4 md:tw-pt-8 md:tw-flex tw-justify-center">
                        <div className="md:tw-flex tw-flex-wrap sm:tw-flex-nowrap tw-justify-around tw-items-center tw-base-shadow tw-px-4 tw-py-2">
                            {/* Country */}
                            <div className="lg:tw-pr-4">
                                <select className={`tw-text-xs tw-p-2`} value={formData.SelectedCountry}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            SelectedCountry: e.target.value,
                                        })
                                    }}
                                >
                                    <option key="Any Country" value="Any Country">Any Country</option>
                                    {
                                        COUNTRY_KEYS.map((key) => (
                                            <option key={key} value={key}>{key}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            {/* Month */}
                            <div className="lg:tw-pr-4">
                                <select className="tw-text-xs tw-p-2" value={formData.SelectedMonth}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            SelectedMonth: e.target.value,
                                        })
                                    }}
                                >
                                    <option key="Any Month" value="Any Month">Any Month</option>
                                    {
                                        MONTH_VALUES.map((key) => (
                                            <option key={key} value={key}>
                                                {key}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                            {/* Guests */}
                            <div>
                                <input type="number" value={formData.Guests ? formData.Guests : ""} min={0}
                                    onChange={(e) => {
                                        const numberValue = +String(e.target.value);
                                        if (typeof numberValue === "number" && numberValue > 0) {
                                            setFormData({
                                                ...formData,
                                                Guests: numberValue,
                                            })
                                        } else if (numberValue === 0) {
                                            setFormData({
                                                ...formData,
                                                Guests: 0,
                                            })
                                        }
                                    }} placeholder="Add Guests" className="tw-p-2 tw-text-xs tw-placeholder-black tw-max-w-[120px]" />
                            </div>
                            {/* Search Icon */}
                            <div className="tw-w-full tw-justify-center tw-items-center tw-px-4 lg:tw-px-0">
                                <button
                                    onClick={() => {
                                        if (formData.SelectedCountry.length > 0 && formData.SelectedCountry !== "Any Country") {
                                            window.location.href = `/country/${formData.SelectedCountry.toLowerCase()}`;
                                        }
                                    }}
                                    className="tw-bg-safari-green tw-w-full tw-flex tw-justify-center tw-items-center tw-rounded-xl md:tw-rounded-[50%] tw-my-2 lg:tw-my-0"
                                >
                                    <Search size={30} color="white" className="tw-py-1" />
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Tour Operator - MEDIUM*/}
                    <div className="tw-hidden md:tw-flex lg:tw-hidden tw-w-full tw-justify-center tw-items-center tw-pt-8">
                        <Link to="/register-as-a-tour-operator">
                            <h5 className="tw-text-xs tw-px-4 tw-font-bold">Register as a Tour Operator</h5>
                        </Link>
                    </div>

                    {/* Register + Profile */}
                    <div className="tw-w-[33%] tw-hidden md:tw-block">
                        <div className="tw-flex tw-items-center tw-pt-8 tw-justify-end">

                            {/* Tour Operator - LARGE*/}
                            <div className="tw-hidden lg:tw-ml-[5vw] tw-mb-0 lg:tw-flex tw-w-full tw-justify-center tw-text-center">
                                <Link to="/register-as-a-tour-operator">
                                    <h5 className="tw-text-xs tw-px-4 tw-font-bold">Register as a Tour Operator</h5>
                                </Link>
                            </div>

                            <div className="tw-flex tw-border-[1px] tw-gap-2 tw-justify-around tw-items-center tw-rounded-3xl tw-shadow-lg tw-p-1">
                                <div className="tw-mr-2">
                                    <div className="tw-relative tw-p-3 tw-cursor-pointer" onClick={() => {
                                        navigate('/discover-tour-operators')
                                    }}>
                                        <ShoppingCart size={18} className="tw-text-safari-green" />
                                        {
                                            cartSelection > 0 ?
                                                <div className=" tw-absolute tw-top-0 tw-right-0 tw-text-sm tw-text-safari-green">
                                                    {cartSelection}
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                <div className="tw-bg-gray-100 tw-p-1 tw-rounded-[50%] tw-h-[30px] tw-w-[30px] tw-flex tw-items-center tw-justify-center tw-mr-2">
                                    <User
                                        size={18}
                                        onClick={() => setOpen(!open)}
                                        className="hover:tw-cursor-pointer"
                                    />
                                    <Register
                                        open={open}
                                        setOpen={setOpen}
                                        operatorLogin={false}
                                        signUpView={true}
                                        stayOnPage={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Tour Operator - SMALL*/}
                    <div className="tw-hidden tw-mt-4 tw-mb-0 tw-flex tw-w-full tw-justify-center tw-text-center">
                        <Link to="/register-as-a-tour-operator">
                            <h5 className="tw-text-xs tw-pr-4 tw-font-bold">Register as a Tour Operator</h5>
                        </Link>
                    </div>
                </div>

                {/* SMALL NAVBAR */}
                <div className="md:tw-hidden tw-flex tw-flex-col tw-pt-4">
                    <div className="tw-flex tw-w-full tw-px-1">
                        <div onClick={() => setOpenPopup(!openPopup)} className="tw-flex tw-flex-row tw-items-center tw-base-shadow tw-px-4 tw-py-2 tw-w-full">
                            <div>
                                <Search size={25} color="black" className="" />
                            </div>
                            <div className="tw-flex tw-flex-col tw-pl-4 tw-text-md">
                                <div>
                                    Choose your adventure!
                                </div>
                                <div className="tw-text-xs tw-text-gray-400">
                                    Add Country - Add Month - Add Guests
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >

        </div>
    )
    return (
        <div className="tw-absolute tw-top-0 tw-bottom-0 tw-z-50">
            {/* MOBILE POP-UP MENU*/}
            <div className="tw-fixed tw-top-0 tw-left-0 tw-w-screen tw-h-screen tw-bg-white">
                <div className="">
                    <div className="tw-flex tw-flex-col tw-fixed tw-w-full tw-h-full tw-justify-items-center tw-pt-6">

                        <div className="tw-pl-4">
                            <X size={40} onClick={() => setOpenPopup(!openPopup)} color="black" className="tw-border-2 tw-border-gray-300 tw-rounded-full tw-p-1 hover:tw-shadow-xl tw-cursor-pointer tw-z-50" />
                        </div>

                        <div className="tw-pt-4 tw-flex tw-flex-col tw-items-center">
                            <div className="tw-flex tw-flex-row tw-items-center tw-h-[120px] tw-w-11/12 tw-base-shadow tw-mb-4">
                                <div className="tw-flex tw-flex-col  tw-w-full tw-mx-8">
                                    <div className="tw-text-xl tw-font-bold">
                                        Country
                                    </div>
                                    <div className=" tw-flex tw-flex-row tw-pt-4 tw-items-center tw-justify-between">
                                        <div className="tw-text-md">
                                            Select country:
                                        </div>
                                        {/* Pop-up Country */}
                                        <div className="tw-border tw-border-gray-300 tw-px-4 tw-py-1 tw-rounded-lg">
                                            <select className={`tw-text-sm`} value={formData.SelectedCountry}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        SelectedCountry: e.target.value,
                                                    })
                                                }}
                                            >
                                                <option key="Any Country" value="Any Country">Any Country</option>
                                                {
                                                    COUNTRY_KEYS.map((key) => (
                                                        <option key={key} value={key}>{key}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tw-flex tw-flex-row tw-items-center tw-h-[120px] tw-w-11/12 tw-base-shadow tw-mb-4" >
                                <div className="tw-flex tw-flex-col tw-w-full tw-mx-8">
                                    <div className="tw-text-xl tw-font-bold">
                                        Month
                                    </div>
                                    <div className=" tw-flex tw-flex-row tw-pt-4 tw-items-center tw-justify-between">
                                        <div className="tw-text-md">
                                            Select month:
                                        </div>
                                        {/* Pop-up Month */}
                                        <div className="tw-border tw-border-gray-300 tw-px-5 tw-py-1 tw-rounded-lg">
                                            <select className="tw-text-sm" value={formData.SelectedMonth}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        SelectedMonth: e.target.value,
                                                    })
                                                }}
                                            >
                                                <option key="Any Month" value="Any Month">Any Month</option>
                                                {
                                                    MONTH_VALUES.map((key) => (
                                                        <option key={key} value={key}>
                                                            {key}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tw-flex tw-flex-row tw-items-center tw-h-[120px] tw-w-11/12 tw-base-shadow tw-mb-4">
                                <div className="tw-flex tw-flex-col tw-w-full tw-mx-8">
                                    <div className="tw-text-xl tw-font-bold">
                                        Guests
                                    </div>
                                    <div className="tw-flex tw-flex-row tw-pt-4 tw-items-center tw-justify-between">
                                        <div className="tw-text-md">
                                            Select guests:
                                        </div>
                                        {/* Pop-up Guests */}
                                        <div className="tw-border tw-border-gray-300 tw-px-1 tw-py-1 tw-rounded-lg">
                                            <input type="number" value={formData.Guests ? formData.Guests : ""} min={0}
                                                onChange={(e) => {
                                                    const numberValue = +String(e.target.value);
                                                    if (typeof numberValue === "number" && numberValue > 0) {
                                                        setFormData({
                                                            ...formData,
                                                            Guests: numberValue,
                                                        })
                                                    } else if (numberValue === 0) {
                                                        setFormData({
                                                            ...formData,
                                                            Guests: 0,
                                                        })
                                                    }
                                                }} placeholder="Add Guests" className="tw-p-2 tw-text-sm tw-placeholder-black tw-max-w-[120px]" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Pop-up Search Icon */}
                            <div className="tw-justify-center tw-items-center tw-w-11/12">
                                <button
                                    onClick={() => {
                                        if (formData.SelectedCountry.length > 0 && formData.SelectedCountry !== "Any Country") {
                                            window.location.href = `/country/${formData.SelectedCountry.toLowerCase()}`;
                                        }
                                    }}
                                    className="tw-bg-safari-green tw-shadow-lg tw-text-xl tw-text-white tw-h-[50px] tw-w-full tw-flex tw-justify-center tw-items-center tw-rounded-xl"
                                >Search &nbsp;
                                    <Search size={30} color="white" className="" />
                                </button>
                            </div>
                            <div className="tw-mt-4">
                                <Link to="/register-as-a-tour-operator" onClick={() => setOpenPopup(false)}>Register as a Tour Operator</Link>
                            </div>
                            <div className="tw-mt-8 tw-w-full tw-px-4">
                                <button onClick={() => {
                                    setOpenPopup(false);
                                    setOpen(true);
                                }} className="tw-white-button tw-w-full">Login or Sign Up</button>
                            </div>
                            <Register
                                open={open}
                                setOpen={setOpen}
                                operatorLogin={false}
                                signUpView={false}
                                stayOnPage={false}
                            />
                        </div>
                    </div >
                </div>
            </div>
        </div>
    )
};

export {
    Navbar,
}