import { TagIDS } from "./tags";

interface Activity {
    Name: string;
    Description: string;
    Tags: { [key: string]: boolean }
    BlogPostLink: string;
    ImageURL: string,
};

enum ActivityIDS {
    // Tanzania
    KILIMANJARO_MACHAME = "Mt.Kilimanjaro",
    SERENGETI = "Serengeti",
    NGORONGORO_CRATER = "Ngorongoro Crater",
    ZANZIBAR = "Zanzibar",

    // Botswana
    OKAVANGO_DELTA = "Okavango Delta",
    CHOBE_NATIONAL_PARK = "Chobe National Park",
    KGALAGADI_PARK = "Kgalagadi Transfrontier Park",

    // Kenya
    AMBOSELI_NATIONAL_PARK = "Amboseli National Park",
    MAASAI_MARA = "Maasai Mara",
    TSAVO_NATIONAL_PARK = "Tsavo National Park",

    // Namibia
    ETOSHA_NATIONAL_PARK = "Etosha National Park",
    SKELETON_COAST = "Skeleton Coast",
    SOSSUSVLEI = "Sossusvlei",

    // South Africa
    KRUGER_NATIONAL_PARK = "Kruger National Park",
    TABLE_MOUNTAIN = "Table Mountain",
    KIRSTENBOSCH_PARK = "Kirstenbosch National Botanical Garden",

    // Zimbabwe
    HWANGE_PARK = "Hwange National Park",
    MATOBO_NATIONAL_PARK = "Matobo National Park",
    NYANGA_NATIONAL_PARK = "Nyanga National Park",

    // Rwanda
    VOLCANOES_NATIONAL_PARK = "Volcanoes National Park",
    LAKE_KIVU = "Lake Kivu",
    NYUNGWE_NATIONAL_PARK = "Nyungwe National Park",

    // Uganda
    MGAHINGA_GORILLA_NATIONAL_PARK = "Mgahinga Gorilla National Park",
    BWINDI_IMPENETRABLE_NATIONAL_PARK = "Bwindi Impenetrable National Park",
    MURCHISON_FALLS = "Murchison Falls National Park",
}


// Tanzania
const KILIMANJARO_MACHAME : Activity = {
    Name: "Mt. Kilimanjaro",
    Description: "Located in Tanzania, Mount Kilimanjaro is Africa's tallest mountain at 5,898 meters. The world's largest free-standing mountain.",
    Tags: { 
        [TagIDS.ADVENTURE_ACTIVITIES]: true,
        [TagIDS.MOUNTAIN_CLIMBING]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fkilimanjaro.jpg?alt=media&token=51eaa4c4-4293-4f03-a56a-eeafdccd021c",
};

const SERENGETI : Activity = {
    Name: ActivityIDS.SERENGETI,
    Description: "The Serengeti National Park is a large national park in northern Tanzania. The Serengeti is well known for the largest annual migration in the world.",
    Tags: {
        [TagIDS.SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fserengeti.jpg?alt=media&token=3bc775d6-1afa-48ec-9a1f-01be81ddf297",
}

const NGORONGORO_CRATER : Activity = {
    Name: ActivityIDS.NGORONGORO_CRATER,
    Description: "The Ngorongoro Crater is a UNESCO World Heritage Site located in the Ngorongoro District, and was formed when a large volcano exploded and collapsed on itself two to three million years ago.",
    Tags: {
        [TagIDS.SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fngorongoro-crater.jpg?alt=media&token=bd199c02-7c1d-4e54-ab4a-2d025fd0e8a3"
}

const ZANZIBAR : Activity = {
    Name: ActivityIDS.ZANZIBAR,
    Description: "A Tanzanian archipelago off the coast of East Africa. The main island, Unguja, is home to Stone Town (a historic trade center with Swahili and Islamic influences), and some of the most beautiful beaches.",
    Tags: {
        [TagIDS.CULTURAL]: true,
        [TagIDS.BEACHES]: true,
        [TagIDS.SCUBA_DIVING]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fzanzibar.jpg?alt=media&token=5460cfab-9fca-4720-bfa0-5af5e6366209"
}

// Botswana
const OKAVANGO_DELTA : Activity = {
    Name: "Okavango Delta",
    Description: "A vast inland river delta in northern Botswana, known for its sprawling grassy plains.",
    Tags: {
        [TagIDS.SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fokavango.jpg?alt=media&token=c9bdf9fc-ccee-4441-bc89-7042c39b6390",
}

const CHOBE_NATIONAL_PARK : Activity = {
    Name: ActivityIDS.CHOBE_NATIONAL_PARK,
    Description: "Chobe National Park is Botswana's first national park, and also the most biologically diverse. Located in the north of the country, it is Botswana's third largest park.",
    Tags: {
        [TagIDS.SAFARI]: true,
        [TagIDS.OPEN_CAR_SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fchobe-national-park.jpg?alt=media&token=565dbdd0-02b3-47a2-a71e-c5e5e937d5bf"
}

const KGALAGADI_PARK : Activity = {
    Name: ActivityIDS.KGALAGADI_PARK,
    Description: "Kgalagadi Transfrontier Park is a large wildlife preserve and conservation area that borders South Africa and Botswana. The terrain consists of red sand dunes, sparse vegetation, occasional trees, and the dry riverbeds of the Nossob and Auob Rivers.",
    Tags: {
        [TagIDS.SAFARI]: true,
        [TagIDS.OPEN_CAR_SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fkgalagadi-park.jpg?alt=media&token=46003978-fb1b-409d-80ed-f8dd66b1e329"
}

// Kenya
const AMBOSELI_NATIONAL_PARK : Activity = {
    Name: ActivityIDS.AMBOSELI_NATIONAL_PARK,
    Description: "Amboseli National Park, is a national park in Kenya. The park protects two of the five main swamps, and includes a dried-up lake and semiarid vegetation. The park is famous for being the best place in the world to get close to free-ranging elephants.",
    Tags: {
        [TagIDS.SAFARI]: true,
        [TagIDS.OPEN_CAR_SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Famboseli-national-park.jpg?alt=media&token=5cdeff3b-4552-4ac9-bd45-d10ad4f25594"
}

const MAASAI_MARA : Activity = {
    Name: ActivityIDS.MAASAI_MARA,
    Description: "A large national game reserve in Kenya, contiguous with the Serengeti National Park in Tanzania. This location is world-renowed for its exceptional populations of lion, African leopards, cheetah, and African bush elephant.",
    Tags: {
        [TagIDS.SAFARI]: true,
        [TagIDS.OPEN_CAR_SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fmaasai-mara.jpg?alt=media&token=84e240c5-1a8b-4aec-afec-184525a98489"
}

const TSAVO_NATIONAL_PARK : Activity = {
    Name: ActivityIDS.TSAVO_NATIONAL_PARK,
    Description: "One of the oldest and largest parks in Kenya. This park is considered one of world's biodiversity strongholds, and popular for the vast wildlife including the Big Five.",
    Tags: {
        [TagIDS.SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Ftsavo-national-park.jpg?alt=media&token=a2ba25fd-13cb-4b1c-89e9-e05c352284d7"
}

// Namibia

const ETOSHA_NATIONAL_PARK : Activity = {
    Name: ActivityIDS.ETOSHA_NATIONAL_PARK,
    Description: "The gateway to Northern Namibia and Ovamboland, Etosha National Park is known as Namibia’s foremost wildlife sanctuary. What makes this park unique is the floodlit waterhole.",
    Tags: {
        [TagIDS.SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fetosha-national-park.jpg?alt=media&token=b3b84935-c0c9-4453-9255-9de10efc0e9e"
}

const SKELETON_COAST : Activity = {
    Name: ActivityIDS.SKELETON_COAST,
    Description: "On Namibia’s remote western coastline, Skeleton Coast is where desert meets the ocean, famous for the wrecked ships and swirling mists that make for dramatic visuals.",
    Tags: {
        [TagIDS.SAFARI]: true,
        [TagIDS.DESERT]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fskeleton-coast.jpg?alt=media&token=21dbc38c-8b3d-47be-9e22-82a536b29b76"
}

const SOSSUSVLEI : Activity = {
    Name: ActivityIDS.SOSSUSVLEI,
    Description: "Distinguished by the large red dunes that encompass it, Sossusvlei (”dead-end marsh”) is a large, white, salt and clay pan, and one of the largest conservation areas in Africa.",
    Tags: {
        [TagIDS.SAFARI]: true,
        [TagIDS.DESERT]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fsossusvlei.jpg?alt=media&token=67d36306-8d62-4d83-8b21-bd6dc2965d21"
}

// South Africa
const KRUGER_NATIONAL_PARK : Activity = {
    Name: ActivityIDS.KRUGER_NATIONAL_PARK,
    Description: "The largest and oldest national park in South Africa, Kruger National Park has a wildlife experience that ranks one of the best in Africa, with an unrivalled diversity of life.",
    Tags: {
        [TagIDS.SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fkruger-national-park.jpg?alt=media&token=3aeae1a8-a3f8-4ef1-a82b-7c82726d68a3"
}

const TABLE_MOUNTAIN : Activity = {
    Name: ActivityIDS.TABLE_MOUNTAIN,
    Description: "Table Mountain is a flat-topped mountain forming a prominent landmark overlooking the city of Cape Town in South Africa.",
    Tags: {
        [TagIDS.ADVENTURE_ACTIVITIES]: true,
        [TagIDS.MOUNTAIN_CLIMBING]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Ftable-mountain.jpg?alt=media&token=a616c593-a3a5-4246-af05-1c475b264bf2"
}

const KIRSTENBOSCH_PARK : Activity = {
    Name: ActivityIDS.KIRSTENBOSCH_PARK,
    Description: "Acclaimed as one of the great botanical gardens of the world. Kirstenbosch is settled against the eastern slopes of Table Mountain.",
    Tags: {
        [TagIDS.ADVENTURE_ACTIVITIES]: true,
        [TagIDS.SITE_SEEING]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fkirstenbosch-park.jpg?alt=media&token=af472f7c-8ea1-475a-bdc6-29ed33eb39eb"
}

// Zimbabwe
const HWANGE_PARK : Activity = {
    Name: ActivityIDS.HWANGE_PARK,
    Description: "The largest natural reserve in Zimbabwe, close to the edge of the Kalahari desert. Hwange National Park is known for the impressive concentration of elephants that are attracted to the park’s waterholes during the dry season.",
    Tags: {
        [TagIDS.SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fhwange-park.jpg?alt=media&token=ac871c21-8719-4bd0-b1a4-43fb9fcee119"
}

const MATOBO_NATIONAL_PARK : Activity = {
    Name: "Matobo National Park",
    Description: "An area of granite kopjes and wooded valleys, formed over 2,000 million years ago.",
    Tags: {
        [TagIDS.SAFARI]: true,
        [TagIDS.SITE_SEEING]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fmatobo-national-park.jpeg?alt=media&token=54232d16-6ae9-444a-9993-119121a55468"
}

const NYANGA_NATIONAL_PARK : Activity = {
    Name: ActivityIDS.NYANGA_NATIONAL_PARK,
    Description: "With rolling green hills and perennial rivers, Nyanga National Park is situated in one of the most scenic areas of Zimbabwe’s Eastern Highlands, and site of Mount Nyangani.",
    Tags: {
        [TagIDS.SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fmatobo.jpg?alt=media&token=b7566428-6273-4bb2-b1c4-69e0f123d9de"
}

// Rwanda
const VOLCANOES_NATIONAL_PARK : Activity = {
    Name: ActivityIDS.VOLCANOES_NATIONAL_PARK,
    Description: "Home of the endangered mountain gorillas within the Virunga Mountains, Volcanoes National Park is also home to the Karisimbi, Mikeno, and Visoke volcanoes.",
    Tags: {
        [TagIDS.WALKING_SAFARI]: true,
        [TagIDS.SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fgorilla.jpg?alt=media&token=88acc99d-795b-4f76-b7b5-5378368640fe"
}

const LAKE_KIVU : Activity = {
    Name: ActivityIDS.LAKE_KIVU,
    Description: "Surrounded by magnificent mountains and deep emerald green waters, Lake Kivu is Rwanda's largest lake and the sixth largest in Africa.",
    Tags: {
        [TagIDS.SITE_SEEING]: true,
        [TagIDS.BEACHES]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Flake-kivu.jpg?alt=media&token=c0542453-8b95-4355-add1-31c5b287e32d"
}


const NYUNGWE_NATIONAL_PARK : Activity = {
    Name: ActivityIDS.NYUNGWE_NATIONAL_PARK,
    Description: "One of the oldest rainforests in Africa and rich in diversity, Nyungwe National Park is in a mountainous region thriving with wildlife.",
    Tags: {
        [TagIDS.ADVENTURE_ACTIVITIES]: true,
        [TagIDS.WALKING_SAFARI]: true,
        [TagIDS.SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fnyungwe-forest.jpeg?alt=media&token=c08dfc4f-93d3-45fa-ab4b-d26137f43751"
}


// Uganda
const MGAHINGA_GORILLA_NATIONAL_PARK : Activity = {
    Name: ActivityIDS.MGAHINGA_GORILLA_NATIONAL_PARK,
    Description: "Covering the northern slopes of the three northernmost Virunga Volcanoes, Mgahinga Gorilla National Park has tremendous importance as this area was refuge to many animals and mountain plants through the ice ages.",
    Tags: {
        [TagIDS.ADVENTURE_ACTIVITIES]: true,
        [TagIDS.WALKING_SAFARI]: true,
        [TagIDS.SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fbwindi-impenetrable.jpg?alt=media&token=0f71f414-8dd2-4e36-8c3b-097cf5430e50",
}

const BWINDI_IMPENETRABLE_NATIONAL_PARK : Activity = {
    Name: ActivityIDS.BWINDI_IMPENETRABLE_NATIONAL_PARK,
    Description: "With more than 160 species of tree and over 100 species of ferns, Bwindi Impenetrable National Park is known for its exceptional biodiversity, including birds, buterflies, and mountain gorillas.",
    Tags: {
        [TagIDS.SAFARI]: true,
        [TagIDS.WALKING_SAFARI]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fbwindi-impenetrable.jpeg?alt=media&token=44e9ae49-8af0-4930-91a2-60616ccf12d4",
}

const MURCHISON_FALLS : Activity = {
    Name: ActivityIDS.MURCHISON_FALLS,
    Description: "Known to be one of the most powerfull waterfalls in the world, Murchison Falls is also home to a variety of beautiful landscapes and an abundance of wildlife.",
    Tags: {
        [TagIDS.SAFARI]: true,
        [TagIDS.BOAT_TOURS]: true,
    },
    BlogPostLink: "",
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fmurchison-falls.jpeg?alt=media&token=7092c8d0-c451-4fdb-b969-85999404b4cf"
}

const ACTIVITIES : { [key: string] : Activity } = {
    // Tanzania
    [ActivityIDS.KILIMANJARO_MACHAME]: KILIMANJARO_MACHAME,
    [ActivityIDS.SERENGETI]: SERENGETI,
    [ActivityIDS.NGORONGORO_CRATER]: NGORONGORO_CRATER,
    [ActivityIDS.ZANZIBAR]: ZANZIBAR,

    // Botswana
    [ActivityIDS.OKAVANGO_DELTA]: OKAVANGO_DELTA,
    [ActivityIDS.CHOBE_NATIONAL_PARK]: CHOBE_NATIONAL_PARK,
    [ActivityIDS.KGALAGADI_PARK]: KGALAGADI_PARK,
    
    // Kenya
    [ActivityIDS.AMBOSELI_NATIONAL_PARK]: AMBOSELI_NATIONAL_PARK,
    [ActivityIDS.MAASAI_MARA]: MAASAI_MARA,
    [ActivityIDS.TSAVO_NATIONAL_PARK]: TSAVO_NATIONAL_PARK,

    // Namibia
    [ActivityIDS.ETOSHA_NATIONAL_PARK]: ETOSHA_NATIONAL_PARK,
    [ActivityIDS.SKELETON_COAST]: SKELETON_COAST,
    [ActivityIDS.SOSSUSVLEI]: SOSSUSVLEI,

    // South Africa
    [ActivityIDS.KRUGER_NATIONAL_PARK]: KRUGER_NATIONAL_PARK,
    [ActivityIDS.TABLE_MOUNTAIN]: TABLE_MOUNTAIN,
    [ActivityIDS.KIRSTENBOSCH_PARK]: KIRSTENBOSCH_PARK,

    // Zimbabwe
    [ActivityIDS.HWANGE_PARK]: HWANGE_PARK,
    [ActivityIDS.NYANGA_NATIONAL_PARK]: NYANGA_NATIONAL_PARK,
    [ActivityIDS.MATOBO_NATIONAL_PARK]: MATOBO_NATIONAL_PARK,

    // Rwanda
    [ActivityIDS.NYUNGWE_NATIONAL_PARK]: NYUNGWE_NATIONAL_PARK,
    [ActivityIDS.LAKE_KIVU]: LAKE_KIVU,
    [ActivityIDS.VOLCANOES_NATIONAL_PARK]: VOLCANOES_NATIONAL_PARK,

    // Uganda
    [ActivityIDS.MGAHINGA_GORILLA_NATIONAL_PARK]: MGAHINGA_GORILLA_NATIONAL_PARK,
    [ActivityIDS.BWINDI_IMPENETRABLE_NATIONAL_PARK]: BWINDI_IMPENETRABLE_NATIONAL_PARK,
    [ActivityIDS.MURCHISON_FALLS]: MURCHISON_FALLS,
}


export {
    ActivityIDS,
    ACTIVITIES,
}
