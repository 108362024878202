
import React from 'react'
import { Instagram, Facebook, Youtube} from 'react-feather';
import { Link } from "gatsby";
import { WrapPageFooter } from './WrapPageFooter';


function Footer() {

    //GENERAL
    let general = [
        // {name:"About Us",link:"/"},
        {name:"Book a Safari",link:"/"},
        {name:"Register as a Tour Operator", link:"/register-as-a-tour-operator"},
        {name:"User Dashboard", link:"/user"},
        {name:"Blog", link:"https://blog.mycloudsafari.com"}
    ];
    let generalList = general.map(general => <li className='hover:tw-underline hover:tw-cursor-pointer tw-w-fit hover:tw-text-neutral-600'><Link to={general.link}>{general.name}</Link></li>)

    //COUNTRY
    let countriesLeft = ["Botswana","Kenya","Namibia","Rwanda"]
    let countriesLeftList = countriesLeft.map(countriesLeft => <li className='hover:tw-underline hover:tw-cursor-pointer tw-w-fit hover:tw-text-neutral-600'><Link to={"/country/" + countriesLeft.toLowerCase()}>{countriesLeft}</Link></li>)

    let countriesRight = ["South Africa","Tanzania","Uganda","Zimbabwe"]
    let countriesRightList = countriesRight.map(countriesRight => <li className='hover:tw-underline hover:tw-cursor-pointer tw-w-fit hover:tw-text-neutral-600'><Link to={"/country/" + countriesRight.toLowerCase()}>{countriesRight}</Link></li>)


    //GET HELP
    let help = [
        // {name:"About Us",link:"/"},
        // {name:"Contact",link:"/"},
        {name:"FAQ", link:"/register-as-a-tour-operator#test"},
        {name:"Terms & Conditions", link:"/terms-and-conditions"},
        
    ];
    let helpList = help.map(help => <li className='hover:tw-underline hover:tw-cursor-pointer tw-w-fit hover:tw-text-neutral-600'><Link to={help.link}>{help.name}</Link></li>)

  return (
    <WrapPageFooter>
    <div className="tw-flex tw-flex-col tw-bg-neutral-100 tw-text-neutral-800 tw-font-bold tw-text-xs tw-relative tw-inset-x-0 tw-max-w-screen">
        <div className='tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-p-10 tw-px-20'>
            <div className='tw-border-b tw-py-3 tw-mb-4 md:tw-border-none md:tw-py-0 md:tw-mb-0'>
                <h1 className='tw-pb-4 '>General</h1>
                <ul className='tw-font-light tw-space-y-2.5 tw-text-neutral-700 '>
                    {generalList}
                </ul>
            </div>
            <div className='tw-border-b tw-py-3 tw-mb-4 md:tw-border-none md:tw-py-0 md:tw-mb-0'> 
                <h1 className='tw-pb-4'>Safaris By Country</h1>
                <div className='tw-flex tw-space-x-14' >
                    <ul className='tw-font-light tw-space-y-2.5 tw-text-neutral-700'>
                        {countriesLeftList}
                    </ul>
                    <ul className='tw-font-light tw-space-y-2.5 tw-text-neutral-700'>
                        {countriesRightList}
                    </ul>
                </div>
            </div>
            <div className='tw-border-b tw-py-3 tw-mb-4 md:tw-border-none md:tw-py-0 md:tw-mb-0'>
                <h1 className='tw-pb-4'>Get Help</h1>
                <ul className='tw-font-light tw-space-y-2.5 tw-text-neutral-700'>
                    {helpList}
                </ul>
            </div>
            <div className='tw-flex tw-flex-col tw-items-center tw-basis-1/6'>
                <h1 className='tw-pb-4'>Contact Us</h1>
                <div className='tw-flex tw-flex-col tw-items-center tw-font-normal tw-text-neutral-400 tw-space-y-2.5'>
                    <a href='tel:+18888590890' className='hover:tw-cursor-pointer tw-w-fit hover:tw-text-neutral-600'>+1 (888) 859 0890</a>
                    <a href='mailto:hello@mycloudsafari.com' className='hover:tw-cursor-pointer tw-w-fit hover:tw-text-neutral-600'>hello@mycloudsafari.com</a>
                </div>
            </div>
        </div>
        <div className='tw-border-t border-neutral-200 tw-flex tw-flex-wrap tw-justify-between tw-h-14 tw-w-11/12 tw-self-center tw-max-w-screen'>
            <div className='tw-flex tw-flex-row tw-font-normal tw-text-xxs tw-items-center tw-align-bottom' >
                <p> ⓒ 2022 CLOUDSAFARI INC. All Rights Reserved</p>
            </div>
            <div className='tw-flex tw-flex-row tw-flex-wrap tw-items-center lg:tw-w-20 tw-justify-between'>
                <Link target="_blank" to="https://www.instagram.com/mycloudsafari/"><Instagram className='tw-text-neutral-800 hover:tw-cursor-pointer tw-w-fit hover:tw-text-neutral-600'  size={23} /></Link>
                <Link target="_blank" to="https://www.facebook.com/mycloudsafari"><Facebook className='tw-text-neutral-800 hover:tw-cursor-pointer tw-w-fit hover:tw-text-neutral-600'  size={23} /></Link>
                {/* <Youtube className='tw-text-neutral-800 hover:tw-cursor-pointer tw-w-fit hover:tw-text-neutral-600'  size={23} /> */}
            </div>
        </div>

    </div>
    </WrapPageFooter>
  )
}

export default Footer