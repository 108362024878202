import React from "react";


const ADMIN_PAGES: { [key: string]: string } = {
    "Home": "",
    "Countries": "country",
    "Activities": "activity",
    "Tags": "tags",
    "Tour Operators": "operators",
};

const AdminNav = () => {
    return (
        <>
        <h2 className="tw-text-lg tw-font-bold">
            Nav Links:
        </h2>
        <div className="tw-flex tw-flex-wrap">
            {
                Object.keys(ADMIN_PAGES).map((key) => {
                    return (
                        <a key={key} href={`/admin-team/${ADMIN_PAGES[key]}`} className="tw-p-2 tw-mr-3 tw-mb-3 tw-mt-3 tw-border tw-rounded hover:tw-shadow-xl">
                            {key}
                        </a>
                    )
                })
            }
        </div>
        </>
    )
}

export {
    AdminNav,
}