import React, { ReactNode, useState } from "react";
import { OperatorNav } from "../../components/OperatorNav";

const wrapPages = ({ element } : { element: ReactNode }) => {
    return (
        <div className="tw-flex tw-flex-wrap md:tw-flex-nowrap tw-h-full tw-min-h-[100vh] tw-w-full tw-bg-light-gray">
            <div className="tw-w-full md:tw-block md:tw-w-[200px]">
                <OperatorNav />
            </div>
            <div className="tw-p-6 md:tw-p-8 lg:tw-p-12 tw-w-full">
                {element}
            </div>
        </div>
    )
}

export default wrapPages;
export {
    wrapPages,
}