import './src/styles/global.css';
import 'react-toastify/dist/ReactToastify.css';
import type { GatsbyBrowser } from "gatsby";
import { FirebaseAuthProvider } from "./src/context/firebase-auth";
import { TempFormDataProvider } from "./src/context/temp-form-data";
import { ToastContainer } from "react-toastify";
import * as React from "react";


export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {

return (
    <TempFormDataProvider>
        <FirebaseAuthProvider>
        {element}
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        </FirebaseAuthProvider>
    </TempFormDataProvider>
  )
}
