import * as React from "react";
import ClientActivityMessage from "shared-models/build/client/activities";

interface TempFormData {
    Selection: { [key: string]: { [key: string]: ClientActivityMessage } };
    SelectedCountry: string;
    SelectedMonth: string;
    Months: { [key: string]: boolean };
    Guests: number;
};

function isTempFormData(o: any): o is TempFormData {
    return "Selection" in o && "SelectedCountry" in o && "SelectedMonth" in o && "Months" in o && "Guests" in o;
  }

interface TempFormDataValue {
    formData: TempFormData;
    setFormData: React.Dispatch<React.SetStateAction<TempFormData>>;
}

const initialFormData = {
    Selection: {},
    Months: {},
    Guests: 0,
    SelectedCountry: "",
    SelectedMonth: "",
}

const initialTempFormData : TempFormDataValue = {
    formData: initialFormData,
    setFormData: () => {},
};

const TempFormDataContext = React.createContext(initialTempFormData);

interface Props {
    children: React.ReactNode;
}

const TempFormDataProvider: React.FC<Props>  = ({ children }) => {
    const [formData, setFormData] = React.useState<TempFormData>(initialFormData);

    React.useEffect(() => {
        if (!window.localStorage.getItem("cloudsafari-form-data")) {
            window.localStorage.setItem("cloudsafari-form-data", JSON.stringify(formData));
        }
    }, []);

    React.useEffect(() => {
        if (JSON.stringify(formData) !== JSON.stringify(initialFormData)) {
            window.localStorage.setItem("cloudsafari-form-data", JSON.stringify(formData));
        }
    }, [formData]);

    React.useEffect(() => {
        const loadFormData = window.localStorage.getItem("cloudsafari-form-data");
        if (typeof loadFormData === "string") {
            const formData = JSON.parse(loadFormData);
            if (isTempFormData(formData)) {
                setFormData(formData);
            }
        }
    }, []);

    return (
        <TempFormDataContext.Provider value={{ formData, setFormData }}>
            {children}
        </TempFormDataContext.Provider>
    )
}

export {
    TempFormDataContext,
    TempFormDataProvider,
}