"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfClientOperatorFiles = exports.getDefaultClientOperatorFiles = exports.instanceOfClientOperatorMessageBase = exports.getDefaultClientOperatorMessage = exports.instanceOfClientOperatorMessage = void 0;
const files_1 = require("../files");
;
const getDefaultClientOperatorFiles = () => {
    return {
        Video: (0, files_1.getDefaultFile)(),
        Logo: (0, files_1.getDefaultFile)(),
        Images: [],
    };
};
exports.getDefaultClientOperatorFiles = getDefaultClientOperatorFiles;
const instanceOfClientOperatorFiles = (object) => {
    return ('Video' in object &&
        'Logo' in object &&
        'Images' in object);
};
exports.instanceOfClientOperatorFiles = instanceOfClientOperatorFiles;
const getDefaultClientOperatorMessage = () => {
    return {
        OrgId: 0,
        Uuid: "",
        OrgName: "",
        OrgAvatarUrl: "",
        OrgVideoUrl: "",
        FoundedDate: new Date(),
        NumberOfVehicles: 1,
        TeamSize: 1,
        TourType: "",
        AgeRange: "",
        AboutUs: "",
        TourPackages: [],
        Countries: {},
        Activities: {},
        Images: [],
        Website: "",
        ShortDescription: "",
    };
};
exports.getDefaultClientOperatorMessage = getDefaultClientOperatorMessage;
const instanceOfClientOperatorMessage = (object) => {
    return ('OrgId' in object &&
        'Uuid' in object &&
        'OrgName' in object &&
        'OrgAvatarUrl' in object &&
        'OrgVideoUrl' in object &&
        'FoundedDate' in object &&
        'NumberOfVehicles' in object &&
        'TeamSize' in object &&
        'TourType' in object &&
        'AgeRange' in object &&
        'AboutUs' in object &&
        'TourPackages' in object &&
        'Countries' in object &&
        'Activities' in object &&
        'Images' in object &&
        'Website' in object &&
        'ShortDescription' in object);
};
exports.instanceOfClientOperatorMessage = instanceOfClientOperatorMessage;
const instanceOfClientOperatorMessageBase = (object) => {
    return ('OrgId' in object &&
        'Uuid' in object &&
        'OrgName' in object &&
        'OrgAvatarUrl' in object &&
        'OrgVideoUrl' in object &&
        'FoundedDate' in object &&
        'NumberOfVehicles' in object &&
        'TeamSize' in object &&
        'TourType' in object &&
        'AgeRange' in object &&
        'AboutUs' in object &&
        'Website' in object &&
        'FileUrl' in object &&
        'ShortDescription' in object);
};
exports.instanceOfClientOperatorMessageBase = instanceOfClientOperatorMessageBase;
