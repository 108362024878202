import { ALL_MONTHS } from "./months";
import { ActivityIDS } from "./activities";

interface Country {
    Name: string;
    Description: string;
    BestMonthsToTravel: string[];
    AverageCost: number;
    Currency: string;
    Activities: { [key: string]: boolean };
    ImageURL: string;
    BlogPostLink: string;
}

enum AfricanCountries {
    TANZANIA = "Tanzania",
    BOTSWANA = "Botswana",
    KENYA = "Kenya",
    NAMIBIA = "Namibia",
    SOUTH_AFRICA = "South Africa",
    ZIMBABWE = "Zimbabwe",
    RWANDA = "Rwanda",
    UGANDA = "Uganda",
};

const Tanzania : Country = {
    Name: "Tanzania",
    Description: "Tanzania, an unforgettable country nestled in East Africa, is known for its epic plains and wealth of natural beauty and wildlife. You’ll see epic snow-topped mountain, expansive plains, active craters, gorgeous animals, and fall in love with the native cultures. Check this unforgettable vacation off your bucket list by organizing your trip with CloudSafari.",
    BestMonthsToTravel: [ALL_MONTHS.JANUARY, ALL_MONTHS.FEBRUARY],
    AverageCost: 800,
    Currency: "USD",
    Activities: {
        [ActivityIDS.KILIMANJARO_MACHAME]: true,
        [ActivityIDS.SERENGETI]: true,
        [ActivityIDS.NGORONGORO_CRATER]: true,
        [ActivityIDS.ZANZIBAR]: true,
    },
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Fkilimanjaro.jpg?alt=media&token=51eaa4c4-4293-4f03-a56a-eeafdccd021c",
    BlogPostLink: "",
};

const Botswana : Country = {
    Name: "Botswana",
    Description: "A safari tour of Botswana is a magical experience, from striking landscapes to unbelievable game viewing. Botswana is known for having the most abundant wildlife sightings on the planet! Spend time in the wilds with a CloudSafari experience that perfectly balances thrilling adventures with safe game viewing environments. The expert travel operators provide insights about specific areas of Botswana, which vary widely from huge saltpans to the big delta of the Okavango River.",
    BestMonthsToTravel: [ALL_MONTHS.FEBRUARY],
    AverageCost: 800,
    Currency: "USD",
    Activities: {
        [ActivityIDS.OKAVANGO_DELTA]: true,
        [ActivityIDS.CHOBE_NATIONAL_PARK]: true,
        [ActivityIDS.KGALAGADI_PARK]: true,
    },
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/countries%2Fbotswana-1.jpg?alt=media&token=35faf2fa-9f61-4453-89e8-fcf66854c6bd",
    BlogPostLink: "",
};

const Kenya : Country = {
    Name: "Kenya",
    Description: "",
    BestMonthsToTravel: [ALL_MONTHS.JULY, ALL_MONTHS.AUGUST, ALL_MONTHS.SEPTEMBER],
    AverageCost: 700,
    Currency: "USD",
    Activities: {
        [ActivityIDS.AMBOSELI_NATIONAL_PARK]: true,
        [ActivityIDS.MAASAI_MARA]: true,
        [ActivityIDS.TSAVO_NATIONAL_PARK]: true,
    },
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/countries%2Fkenya.jpg?alt=media&token=d702f607-5624-4a4b-81bf-7cc1a47f8797",
    BlogPostLink: "",
};

const Namibia : Country = {
    Name: "Namibia",
    Description: "",
    BestMonthsToTravel: [ALL_MONTHS.JULY, ALL_MONTHS.AUGUST, ALL_MONTHS.SEPTEMBER, ALL_MONTHS.OCTOBER],
    AverageCost: 600,
    Currency: "USD",
    Activities: {
        [ActivityIDS.ETOSHA_NATIONAL_PARK]: true,
        [ActivityIDS.SKELETON_COAST]: true,
        [ActivityIDS.SOSSUSVLEI]: true,
    },
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/countries%2Fnamibia.jpg?alt=media&token=013ed015-2bb1-47af-8c45-14fe8b6bd4b1",
    BlogPostLink: "",
};

const SouthAfrica : Country = {
    Name: "South Africa",
    Description: "",
    BestMonthsToTravel: [ALL_MONTHS.MAY, ALL_MONTHS.JUNE, ALL_MONTHS.JULY, ALL_MONTHS.AUGUST, ALL_MONTHS.SEPTEMBER],
    AverageCost: 550,
    Currency: "USD",
    Activities: {
        [ActivityIDS.KRUGER_NATIONAL_PARK]: true,
        [ActivityIDS.TABLE_MOUNTAIN]: true,
        [ActivityIDS.KIRSTENBOSCH_PARK]: true,
    },
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/countries%2Fsouth-africa.jpg?alt=media&token=61c2b1b0-853d-4524-9d25-92331b1fdab5",
    BlogPostLink: "",
};

const Zimbabwe : Country = {
    Name: "Zimbabwe",
    Description: "",
    BestMonthsToTravel: [ALL_MONTHS.JULY, ALL_MONTHS.AUGUST, ALL_MONTHS.SEPTEMBER, ALL_MONTHS.OCTOBER],
    AverageCost: 950,
    Currency: "USD",
    Activities: {
        [ActivityIDS.HWANGE_PARK]: true,
        [ActivityIDS.NYANGA_NATIONAL_PARK]: true,
        [ActivityIDS.MATOBO_NATIONAL_PARK]: true,
    },
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/countries%2Fzimbabwe.jpg?alt=media&token=f0b8a2e4-7a8c-443e-83b0-bc4260ecf6ca",
    BlogPostLink: "",
};

const Rwanda : Country = {
    Name: "Rwanda",
    Description: "",
    BestMonthsToTravel: [ALL_MONTHS.JUNE, ALL_MONTHS.JULY, ALL_MONTHS.AUGUST, ALL_MONTHS.SEPTEMBER],
    AverageCost: 750,
    Currency: "USD",
    Activities: {
        [ActivityIDS.VOLCANOES_NATIONAL_PARK]: true,
        [ActivityIDS.NYUNGWE_NATIONAL_PARK]: true,
        [ActivityIDS.LAKE_KIVU]: true,
    },
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/countries%2Frwanda.jpg?alt=media&token=ee9ea53f-12c8-418b-ab9c-194a3a3c4561",
    BlogPostLink: "",
}

const Uganda : Country = {
    Name: "Uganda",
    Description: "",
    BestMonthsToTravel: [ALL_MONTHS.JUNE, ALL_MONTHS.JULY, ALL_MONTHS.AUGUST, ALL_MONTHS.DECEMBER, ALL_MONTHS.JANUARY, ALL_MONTHS.FEBRUARY],
    AverageCost: 500,
    Currency: "USD",
    Activities: {
        [ActivityIDS.MGAHINGA_GORILLA_NATIONAL_PARK]: true,
        [ActivityIDS.BWINDI_IMPENETRABLE_NATIONAL_PARK]: true,
        [ActivityIDS.MURCHISON_FALLS]: true,
    },
    ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/countries%2Fuganda.jpg?alt=media&token=1b605b83-45bc-4728-8b33-7de3746c6fad",
    BlogPostLink: "",
}

const ALL_COUNTRIES : { [ key: string ]: Country } = {
    [AfricanCountries.TANZANIA]: Tanzania,
    [AfricanCountries.BOTSWANA]: Botswana,
    [AfricanCountries.KENYA]: Kenya,
    [AfricanCountries.NAMIBIA]: Namibia,
    [AfricanCountries.SOUTH_AFRICA]: SouthAfrica,
    [AfricanCountries.ZIMBABWE]: Zimbabwe,
    [AfricanCountries.RWANDA]: Rwanda,
    [AfricanCountries.UGANDA]: Uganda,
};

export {
    ALL_COUNTRIES,
    AfricanCountries,
}