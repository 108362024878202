import React, { ReactNode } from "react";
import Footer from "../components/Footer";
import { Navbar } from "../components/Navbar";

const wrapPages = ({ element } : { element: ReactNode }) => {
    return (
        <div className="tw-min-h-screen">

        <div className="tw-flex tw-justify-center tw-pt-[5px] md:tw-pt-[20px] tw-pb-[20px] " id="main-app-wrapper">
            <div className="tw-max-w-[1400px] tw-px-4 tw-w-full">
                <Navbar />
            </div>
        </div>

        {/* Line */}
        <div className="tw-h-[1px] tw-bg-gray-200 tw-shadow"></div>
        
        <div className="tw-flex tw-justify-center tw-pt-[5px] md:tw-pt-[20px] tw-pb-[20px] " id="main-app-wrapper">
            <div className="tw-max-w-[1400px] tw-px-4 tw-w-full">
                {element}
            </div>
        </div>
        <div>
            <Footer />
        </div>
        </div>
    )
}

export default wrapPages;
export {
    wrapPages, 
}