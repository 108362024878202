"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FileType;
(function (FileType) {
    FileType["IMAGE"] = "image";
    FileType["VIDEO"] = "video";
    FileType["FILE"] = "file";
})(FileType || (FileType = {}));
;
exports.default = FileType;
