import * as React from "react"
import * as firebase from "firebase/app";
import * as firebaseAuth from "firebase/auth";
import { navigate } from "gatsby";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: process.env.GATSBY_apiKey,
    authDomain: process.env.GATSBY_authDomain,
    databaseURL: process.env.GATSBY_databaseURL,
    projectId: process.env.GATSBY_projectId,
    storageBucket: process.env.GATSBY_storageBucket,
    messagingSenderId: process.env.GATSBY_messagingSenderId,
    appId: process.env.GATSBY_appId,
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = firebaseAuth.getAuth(app);

export const storage = getStorage(app);

if (firebase.getApps().length === 0) {
    firebase.initializeApp(firebaseConfig);
}

export const FirebaseAuthContext = React.createContext<firebaseAuth.User | null>(null);

interface Props {
    children: React.ReactNode;
}

export const FirebaseAuthProvider: React.FC<Props> = ({ children }) => {
    const [user, setUser] = React.useState<firebaseAuth.User | null>(null);
    React.useEffect(() => {
        auth.onAuthStateChanged(
            (user) => {
                setUser(user);
            },
        )
    }, [auth, user]);

    return <FirebaseAuthContext.Provider value={user}>{children}</FirebaseAuthContext.Provider>;
}


export const forceRefreshUser = () => {
    // attempt to force refresh
    try {
        auth.currentUser?.getIdToken(true);
    } catch (e) {
        if (e instanceof Error) {
            console.log(e.message);
        }
    }
};

export const CreateUserWithEmailAndPassword = async (email: string, password: string) : Promise<firebaseAuth.UserCredential> => {
   const userCred = await firebaseAuth.createUserWithEmailAndPassword(auth, email, password)
   forceRefreshUser();
   return userCred;
}

export const LoginEmailAndPassword = async (email: string, password: string) : Promise<[firebaseAuth.UserCredential | null, Error | null]> => {
    try {
        const loginValue = await firebaseAuth.signInWithEmailAndPassword(auth, email, password);
        return [loginValue, null];
    } catch (e) {
        if (e instanceof Error) {
            return [null, e];
        } else {
            return [null, new Error("Unexpected Error Encountered.")];
        }
    }
}

// Google Provider
const googleProvider = new firebaseAuth.GoogleAuthProvider();

// TODO, is any of this needed??
export const SignInWithGoogle = () => {
    firebaseAuth.signInWithPopup(auth, googleProvider)
    .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = firebaseAuth.GoogleAuthProvider.credentialFromResult(result);
        if (credential === null) return;
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
    }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log({ errorCode, errorMessage }); // TODO, UPDATE HERE
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = firebaseAuth.GoogleAuthProvider.credentialFromError(error);
        // ...
    });
}

// Facebook Provider
const facebookProvider = new firebaseAuth.FacebookAuthProvider();

export const SignInWithFacebook = () => {
    firebaseAuth.signInWithPopup(auth, facebookProvider)
    .then((result) => {
        const credential = firebaseAuth.FacebookAuthProvider.credentialFromResult(result);
        if (credential === null) return;
        const token = credential.accessToken;
        const user = result.user;
    }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log({ errorCode, errorMessage }); // TODO, UPDATE HERE
        // The email of the user's account used.
        const email = error.customData.email;
        const credential = firebaseAuth.FacebookAuthProvider.credentialFromError(error);
        // ...
    });
}

export const Logout = async (path: string) => {
    await firebaseAuth.signOut(auth);
    forceRefreshUser();
    // Redirect
    navigate(path);
}

