"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USER_ROLES = exports.CURRENCIES = void 0;
var USER_ROLES;
(function (USER_ROLES) {
    USER_ROLES["TOUR_OPERATOR"] = "TOUR_OPERATOR";
    USER_ROLES["CLOUDSAFARI_ADMIN"] = "CLOUDSAFARI_ADMIN";
})(USER_ROLES || (USER_ROLES = {}));
exports.USER_ROLES = USER_ROLES;
;
var CURRENCIES;
(function (CURRENCIES) {
    CURRENCIES["USD"] = "USD";
    CURRENCIES["EUR"] = "EUR";
})(CURRENCIES || (CURRENCIES = {}));
exports.CURRENCIES = CURRENCIES;
