import React, { ReactNode } from "react";

interface Props {
    children: ReactNode,
}

export const WrapPageFooter: React.FC<Props> = ({ children }) => {
    return (
        <div className="tw-mt-28 tw-m-full tw-left-0 tw-bg-neutral-100 tw-w-screen tw-max-w-full tw-flex tw-justify-center tw-pt-[5px] md:tw-pt-[20px] tw-pb-[20px]">
            <div className="tw-max-w-[1400px] tw-w-full">
                {children}
            </div>
        </div>
    )
}