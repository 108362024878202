import React, { useState, FC, useContext, useEffect } from "react";
import { Modal } from "./Modal";
import { FcGoogle } from "react-icons/fc";
import { CreateUserWithEmailAndPassword, FirebaseAuthContext, LoginEmailAndPassword } from "../context/firebase-auth";
import * as EmailValidator from "email-validator";
import { getCustomClaims, getRequestConfigWithUserToken, handleUserSignUp, sendPostRequest, toastError, toastInfo, toastSuccess } from "../util/util";
import { Link, navigate } from "gatsby";
import axios from "axios";
import { USER_ROLES } from "shared-models/build/constants";
import { User } from "firebase/auth";

interface Props {
    open: boolean,
    setOpen: Function,
    operatorLogin: boolean,
    signUpView: boolean,
    stayOnPage: boolean,
}

const Register: FC<Props> = ({ open, setOpen, operatorLogin, signUpView, stayOnPage }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [userSigningUp, setUserSigningUp] = useState(signUpView);
    const [clickedSignUp, setClickedSignUp] = useState(false);
    const [agreeConditions, setAgreeConditions] = useState(false);
    const [name, setName] = useState<string>("");
    const user = useContext(FirebaseAuthContext);

    useEffect(() => {
        if (user && open && !clickedSignUp) {
            handleRedirect(user);
        } 
    }, [user, open]);

    const handleRedirect = async (firebaseUser: User) => {
        toastInfo(`Logged in as ${firebaseUser.email}`);
        const idTokenResult = await getCustomClaims(firebaseUser);
        if (idTokenResult.claims[USER_ROLES.TOUR_OPERATOR]) {
            navigate('/redirect/login');
        } else {
            navigate('/redirect/login');
        }
    }

    const handleSignUp = async () => {
        // Email Check
        if (!EmailValidator.validate(email)) {
            toastError("Please enter in a valid email.")
            return;
        }

        // Password Check
        if (password.length < 6) {
            toastError("Password must be more than 6 characters in length.")
            return;
        }

        // Check if passwords match
        if (confirmPassword !== password) {
            toastError("Passwords do not match.");
            return;
        }

        // Name
        if (name.length === 0) {
            toastError("Please enter your name.");
            return;
        }

        // Create User in Firebase
        const createUser = await CreateUserWithEmailAndPassword(email, password);

        setPassword("");
        setConfirmPassword("");

        // Redirect for tour operator sign up
        if (createUser) {

            const idTokenResult = await getCustomClaims(createUser.user);
            if (!stayOnPage) {
                if (operatorLogin) {
                    navigate('/redirect/tour-operator-sign-up');
                } else {
                    navigate(`/redirect/user-sign-up?name=${encodeURIComponent(name)}`);
                }
            } else {
                await handleUserSignUp(createUser.user, name);
                setOpen(false);
            }
        }
    }

    const handleLogin = async () => {
        const [userCred, error] = await LoginEmailAndPassword(email, password);
        if (error !== null) {
            toastError(error.message);
        } else if (userCred && error === null) {
            if (!stayOnPage) {
                const idTokenResult = await getCustomClaims(userCred.user);
                if (idTokenResult.claims[USER_ROLES.TOUR_OPERATOR]) {
                    navigate('/redirect/login');
                } else {
                    navigate('/redirect/login');
                }
            } else {
                setOpen(false);
            }
        } else {
            toastError("Something went wrong with the login.")
        }
    }

    return (
        <Modal
            open={open}
            setOpen={(open : boolean) => setOpen(open)}
            contentLabel="Login or Sign Up to CloudSafari"
        >
            <div className="tw-py-[30px]">
                <div className="tw-flex tw-justify-center tw-items-center tw-text-center">
                    <h3 className="tw-mb-6">
                        <span className="tw-font-bold">
                        { operatorLogin ? "Join CloudSafari as a Tour Operator" : "Welcome to CloudSafari!" }
                        </span>
                        <div className="tw-my-2">
                            Please <button onClick={() => setUserSigningUp(false)} className="tw-text-safari-green">login</button> or <button className="tw-text-safari-green" onClick={() => setUserSigningUp(true)}>sign up</button> to continue.
                        </div>
                    </h3>
                </div>
                <div>
                    <div>
                        {
                            userSigningUp ?
                            <input 
                                className="tw-input tw-mb-6"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            :
                            null
                        }
                        <input 
                            className="tw-input"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input 
                            className="tw-input tw-mt-6"
                            placeholder="Password"
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        {
                            userSigningUp ?
                            <>
                            <input 
                                className="tw-input tw-mt-6"
                                placeholder="Confirm Password"
                                type="password"
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                            />
                            <div className="tw-text-sm tw-font-light tw-mt-4 tw-text-center">
                                By signing up you agree to the <a href="/terms-and-conditions" target="_blank" className="tw-text-[blue]">Terms of Service</a> and confirm that you are 18 or older.
                            </div>
                            <button onClick={() => {
                                setClickedSignUp(true);
                                handleSignUp();
                            }} className="tw-primary-button tw-w-full tw-mt-6 ">
                                Sign Up
                            </button>
                            </>
                            :
                            <button onClick={() => handleLogin()} className="tw-primary-button tw-w-full tw-mt-6 ">
                                Log In
                            </button>
                        }
                        {
                            userSigningUp ?
                            <div>
                                <p className="tw-text-sm tw-font-light tw-mt-8" onClick={() => setUserSigningUp(false)}>
                                    Already have an account? <button className="tw-text-safari-green">Login</button>
                                </p>
                            </div>
                            :
                            <div>
                                <p className="tw-text-sm tw-font-light tw-mt-4" onClick={() => setUserSigningUp(true)}>
                                    Don't have an account? <button className="tw-text-safari-green">Sign up</button>
                                </p>
                            </div>
                        }
                    </div>
                    {/*
                    <div className="tw-flex tw-items-center tw-justify-around tw-py-8">
                        <hr className="tw-w-2/5" />
                        <h3 className="tw-w-1/5 tw-text-center">
                            or
                        </h3>
                        <hr className="tw-w-2/5" />
                    </div>
                    <div>
                        <button className="tw-white-button tw-w-full tw-flex tw-items-center tw-justify-center tw-text-sm">
                            <FcGoogle size={25} className="tw-mr-2" /> Continue with Google
                        </button>
                    </div>
                    */}
                </div>
            </div>
        </Modal>
    )
}

export {
    Register,
}